import React, { Component } from "react";
import styled from "styled-components"
import { Link } from "gatsby";
import { ShoppingCart } from 'styled-icons/material/ShoppingCart';


const HeaderMinorStyled = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0;
    padding: 10px;


`

const ShopName = styled.span`
    padding: 0px 0px 0px 0px;
    text-align: center;
    font-size: 1em;
    font-weight: 700;

`

const logo = require('./bailey-coffee-logo.svg');



const LinkStyled = styled(Link)`
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    margin-left:30px;
`

const CartSummary = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: bold;
`

const CartItems = styled.span`
    height: 100%;
    flex-direction: column;
    font-size: 0.7em;

`

class HeaderMinor extends Component {
    state = {
        items: 0
    }


    render() {
    
        return (
            <HeaderMinorStyled>

                <ShopName>
                    <LinkStyled to='/'>
                        {this.props.shopName}
                        <img src={logo} alt="Bailey Coffee Roaster"/>

                    </LinkStyled>
                </ShopName>                    

                <CartSummary className="snipcart-summary">
                    <button href="#" className="snipcart-checkout" aria-label="shopping cart"> <ShoppingCart size='40px' /></button>
                    {/* <p>{this.state.items} items</p> */}
        
                    <CartItems className="Header__summary__line">
                     <span class="snipcart-items-count"> &nbsp;</span> items
                    </CartItems>
                </CartSummary>

            </HeaderMinorStyled>
            
        )
    }

}

export default HeaderMinor;
