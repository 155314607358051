import React from "react";
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import { GlobalStyle } from '../styles/globalStyle';
import HeaderMinor from './Headers/HeaderMinor';
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,
         faLock,  
        } from '@fortawesome/free-solid-svg-icons'
import { faCcVisa, 
         faCcMastercard,
         faInstagram, 
       } from '@fortawesome/free-brands-svg-icons'



const PageWrapper = styled.div`
  width: 100%;
  padding: 0px 0px 0px 0px;
`

const MainSection = styled.div`
  width: 100%;
`

const FooterContent = styled.p`
    width: auto;
    color:#F0F6F6;
    font-size: 1.0em;
    line-height: 30px;
    text-align: center;
    padding: 20px 0px 0px 0px;

`


const AlertStrip = styled.div`
    width: 100%;
    background-color:#161032;
    padding: 20px 0px 20px 0px;
    text-align: center;
    color:#ffffff;

`

const FooterStyled = styled.footer`
  width: 100%;
  text-align: right;
  background-color: #C55870;
  color:#c0c0c0;
  padding: 50px 60px 80px 60px;


  @media (max-width: 600px) {
    text-align: center;
  }
`



class Layout extends React.Component {
/* 
  componentDidMount() {
    if (window.Snipcart) {
      window.Snipcart.api.configure('show_continue_shopping', true);
    }
  } */


  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const siteName = ""
    let header

    if (location.pathname === rootPath) {
      header = (
        <HeaderMinor shopName={siteName}>
        <img src="/content/assets/bailey-coffee-logo.png" alt="Bailey Coffee Logo" width="200" height="40"/>
        </HeaderMinor>
        
      )
    } else {
      header = (
        <HeaderMinor shopName={siteName}>
        </HeaderMinor>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />

          <PageWrapper>
            {header}

            <MainSection>{children}</MainSection>
            <AlertStrip>Free express delivery on orders over $65</AlertStrip>
            <FooterStyled> 
             <FooterContent>
              <FontAwesomeIcon icon={faCoffee} size="1x" /> Crafting amazing coffee toasted for flavour in the<a href="https://www.instagram.com/explore/tags/cityofmelbourne/"> #CityOfMelbourne</a> </FooterContent>
<FooterContent><Link to="/about"> About </Link>|<Link to="/contact"> Contact </Link>|<Link to="/terms"> Terms of Service </Link>| <Link to="/timeline"> Timeline</Link> </FooterContent>

<FooterContent> We accept &nbsp;
<FontAwesomeIcon icon={faCcVisa} size="2x" /> &nbsp;
<FontAwesomeIcon icon={faCcMastercard} size="2x" /> &nbsp;
 </FooterContent>

<FooterContent><FontAwesomeIcon icon={faLock} size="1x" />&nbsp;Secure payments with Square</FooterContent>
<FooterContent>Find us on  <a href="https://www.instagram.com/baileycoffee/"><FontAwesomeIcon icon={faInstagram} size="2x" title="find jbcoffee on instagram" /></a> </FooterContent>
<FooterContent>&#169; 2021 Bailey Coffee </FooterContent>

</FooterStyled>
          </PageWrapper>
        </>
      </ThemeProvider>
    )
  }
}

export default Layout



